import { OrganisationDocumentCollectionResource } from '@/models/organisationDocuments/OrganisationDocumentCollectionResource'
import { OrganisationDocumentResource } from '@/models/organisationDocuments/OrganisationDocumentResource'

export class ImplementationCreateRequest {
  public project_id: number
  public process_ids: number[] = []

  constructor(document: OrganisationDocumentCollectionResource | OrganisationDocumentResource) {
    this.project_id = document.project_info.id
  }
}
