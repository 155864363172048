import { formatDateForBackend } from '@/helpers/formatDate'

export class ProjectCloneRequest {
  public project_name: string = ''
  public end_date: Date | null
  public version: string = ''
  public type: 'clone' | 'reference' = 'clone'
  public project_id: number

  constructor({ project_id, type }: { project_id: number, type: 'clone' | 'reference' }) {
    this.type = type
    this.project_id = project_id
    this.end_date = null
  }

  public getRequestData(): { project_name: string, end_date: string, version: string, type: 'clone' | 'reference', project_id: number } {
    return {
      ...this,
      end_date: formatDateForBackend(this.end_date),
    }
  }

}
